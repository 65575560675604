import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import { ScrollPanel } from 'primeng/scrollpanel';
import {AuthService} from '../service/core/auth.service';

enum MenuOrientation {
  STATIC,
  OVERLAY,
  SLIM,
  HORIZONTAL
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit , AfterViewInit {
  layoutMode: MenuOrientation = MenuOrientation.HORIZONTAL;
  darkMenu = false;
  profileMode = 'inline';
  rotateMenuButton?: boolean;
  topbarMenuActive?: boolean;
  overlayMenuActive?: boolean;
  staticMenuDesktopInactive?: boolean;
  staticMenuMobileActive?: boolean;
  layoutMenuScroller?: HTMLDivElement;
  menuClick?: boolean;
  topbarItemClick?: boolean;
  activeTopbarItem: any;
  resetMenu?: boolean;
  menuHoverActive?: boolean;

  @ViewChild('layoutMenuScroller', {static: true}) layoutMenuScrollerViewChild?: ScrollPanel;

  constructor(public auth: AuthService,
              public router: Router) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {this.layoutMenuScrollerViewChild?.moveBar(); }, 100);
  }

  onLayoutClick() {
    if (!this.topbarItemClick) {
      this.activeTopbarItem = null;
      this.topbarMenuActive = false;
    }

    if (!this.menuClick) {
      if (this.isHorizontal() || this.isSlim()) {
        this.resetMenu = true;
      }

      if (this.overlayMenuActive || this.staticMenuMobileActive) {
        this.hideOverlayMenu();
      }

      this.menuHoverActive = false;
    }

    this.topbarItemClick = false;
    this.menuClick = false;
  }

  onMenuButtonClick(event: any) {
    this.menuClick = true;
    this.rotateMenuButton = !this.rotateMenuButton;
    this.topbarMenuActive = false;

    if (this.layoutMode === MenuOrientation.OVERLAY) {
      this.overlayMenuActive = !this.overlayMenuActive;
    } else {
      if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive; } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive; }
    }

    event.preventDefault();
  }

  onMenuClick($event: any) {
    this.menuClick = true;
    this.resetMenu = false;

    if (!this.isHorizontal()) {
      setTimeout(() => {this.layoutMenuScrollerViewChild?.moveBar(); }, 450);
    }
  }

  onTopbarMenuButtonClick(event: any) {
    this.topbarItemClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  onTopbarItemClick(event: any, item: any) {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null; } else {
      this.activeTopbarItem = item; }

    event.preventDefault();
  }

  hideOverlayMenu() {
    this.rotateMenuButton = false;
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  isMobile() {
    return window.innerWidth <= 640;
  }

  isOverlay() {
    return this.layoutMode === MenuOrientation.OVERLAY;
  }

  isHorizontal() {
    return this.layoutMode === MenuOrientation.HORIZONTAL;
  }

  isSlim() {
    return this.layoutMode === MenuOrientation.SLIM;
  }

  changeToStaticMenu() {
    this.layoutMode = MenuOrientation.STATIC;
  }

  changeToOverlayMenu() {
    this.layoutMode = MenuOrientation.OVERLAY;
  }

  changeToHorizontalMenu() {
    this.layoutMode = MenuOrientation.HORIZONTAL;
  }

  changeToSlimMenu() {
    this.layoutMode = MenuOrientation.SLIM;
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/login']);
  }
}
