import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HintComponent } from './hint.component';
import {FormsModule} from "@angular/forms";
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
  imports: [
    CommonModule,
    OverlayPanelModule,
    ButtonModule,
    InputTextareaModule,
    FormsModule
  ],
  declarations: [HintComponent],
  exports: [HintComponent]
})
export class HintModule {}
