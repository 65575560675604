import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'situacao'
})
export class SituacaoPipe implements PipeTransform {
  transform(itens: any[], situacao: boolean): any {
    if (situacao != null) {
      return itens.filter(i => i.situacao === situacao);
    }
    return itens;
  }
}
