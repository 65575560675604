import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class ServicosService {
  public urls: any = {};
  public modulos: any = {};
  private urls$: any;
  private modulos$: any;
  public url: String | undefined;
  
  constructor(public router: Router, public http: HttpClient) {}

  buscarUrlBase(){
    this.url = environment.baseUrl;
    return this.url;
  }

  buscarUrls() {
    if (!this.urls$) {
      this.urls$ = this.buscarUrlsCached();
    }
    return this.urls$;
  }

  buscarUrlsCached() {
    return this.http
      .get<any>(environment.baseUrl + 'servicos/corporativo', { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .pipe(
        shareReplay(1), // Cache
        tap(res => (this.urls = res))
      );
  }

  buscarModulos() {
    if (!this.modulos$) {
      this.modulos$ = this.buscarModulosCached();
    }
    return this.modulos$;
  }

  buscarModulosCached() {
    return this.http
      .get<any>(environment.baseUrl + 'servicos/privado', { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .pipe(
        shareReplay(1), // Cache
        tap(res => (this.modulos = res))
      );
  }

  private reduceVetorParaObjeto(modulos: any): any {
    return modulos.reduce(
      // tslint:disable-next-line:no-shadowed-variable
      (map: { [x: string]: any; }, obj: { nome: string | number; url: any; }) => ((map[obj.nome] = obj.url), map),
      {}
    );
  }
}
