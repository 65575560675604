<ng-template ngFor let-child let-i="index" [ngForOf]="(root ? [item] : item?.items) || []">
  <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child?.badgeStyleClass || ''" *ngIf="child?.visible === false ? false : true">
    <a [href]="child?.url||'#'" (click)="child && itemClick($event,child,i)" (mouseenter)="child && onMouseEnter(i)" class="ripplelink" *ngIf="!child?.routerLink"
      [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child?.target">
      <i *ngIf="child?.icon" [ngClass]="child?.icon"></i>
      <span>{{child?.label}}</span>
      <i class="fa fa-fw fa fa-angle-down menuitem-toggle-icon" *ngIf="child?.items"></i>
      <span class="menuitem-badge" *ngIf="child?.badge">{{child?.badge}}</span>
    </a>

    <a (click)="child && itemClick($event,child,i)" (mouseenter)="child && onMouseEnter(i)" class="ripplelink" *ngIf="child?.routerLink" [routerLink]="child?.routerLink"
      routerLinkActive="active-menuitem-routerlink" [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null"
      [attr.target]="child?.target">
      <i *ngIf="child?.icon" [ngClass]="child?.icon"></i>
      <span>{{child?.label}}</span>
      <i class="fa fa-fw fa fa-angle-down menuitem-toggle-icon" *ngIf="child?.items"></i>
      <span class="menuitem-badge" *ngIf="child?.badge">{{child?.badge}}</span>
    </a>
    <div class="layout-menu-tooltip">
      <div class="layout-menu-tooltip-arrow"></div>
      <div class="layout-menu-tooltip-text">{{child?.label}}</div>
    </div>
    <div class="submenu-arrow" *ngIf="child?.items"></div>
    <ul app-submenu [item]="child" *ngIf="child?.items" [visible]="isActive(i)" [reset]="reset" [@children]="(app.isSlim()||app.isHorizontal())&&root ? isActive(i) ?
                     'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
  </li>
</ng-template>
