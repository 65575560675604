import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {AdminRoutes} from './admin.routing';
import {AdminComponent} from './admin.component';
import { LayoutModule } from '../shared/layout/layout.module';


@NgModule({
    imports: [
        RouterModule.forChild(AdminRoutes),
        CommonModule,
        LayoutModule,
        ScrollPanelModule,
    ],
  declarations: [AdminComponent]
})
export class AdminModule {}
