import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AdminComponent} from '../../../admin/admin.component';
import {AuthService} from '../../../service/core/auth.service';
import {AlertaService} from '../../../service/core/alerta.service';
// @ts-ignore
import {Croppie} from 'croppie/croppie';


@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.css'],
    providers: [AlertaService]
})
export class TopbarComponent implements OnInit {
    public nomeSistema = 'Api CNPJ';
    public showCrop = false;
    private croppie: Croppie;
    public codigoDoProjeto: any;
    public imagemBase64: any;
    public fotoUsuario: any;
    constructor(
        public app: AdminComponent,
        private router: Router,
        public auth: AuthService,
        private alerta: AlertaService
    ) {
    }

    ngOnInit() {
        this.buscarImagem();
    }

    showModalCrop() {
        this.showCrop = true;
        this.crop(this.fotoUsuario);
    }

    select(files: any, fileUpload: any) {
        if (this.croppie) {
            this.croppie.destroy();
        }
        const file = files.files[0];
        this.readFile(file);
        fileUpload.clear();
    }

    private readFile(file: any) {
        const reader = new FileReader();
        reader.onload = e => {
            console.log(e);
            const target: any = e.target;
            this.crop(target.result);
        };
        reader.readAsDataURL(file);
    }

    crop(imagem: any) {
        if (imagem) {
            this.imagemBase64 = imagem;   
            this.fotoUsuario = imagem;         
            const fotoUsuario = document.getElementById('fotoUsuario') as HTMLImageElement;
            if(fotoUsuario){
                fotoUsuario.src = this.imagemBase64;
            }
            setTimeout(() => {
                const imagemDiv = document.getElementById('imagemCrop') as HTMLImageElement;
                if (imagemDiv) {
                  imagemDiv.src = this.imagemBase64;
                }
            }, 500);
          }
    }

    public salvarImagem() {
        this.auth.alterarImagem({foto: this.imagemBase64}).subscribe(
            res => {
                this.alerta.showSuccess('Foto Alterada');
                this.close();
            },
            err => {
                this.alerta.showError(err.error.detalhe || 'Serviço indisponível');
            }
        );
    }


    public buscarImagem() {
        this.auth.buscarImagemUsuario().subscribe(
            (res: any) => { // Explicitly type the response object as any
                var foto = res;
                this.imagemBase64 = foto.foto;
                this.crop(foto.foto);
            },
            err => {
                this.alerta.showError(err.error.detalhe || 'Serviço indisponível');
            }
        );
    }

    private close() {
        this.showCrop = false;
    }

    logout() {
        this.router.navigate(['/login']);
        this.auth.logout();
    }


    buscarProjeto($event: MouseEvent) {
        console.log('Buscar Evento', $event);
    }
}
