import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap, catchError } from 'rxjs/operators';
import { ServicosService } from './servicos.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public usuario: any;
    public menus: any;
    public access_token?: string;
    public alteracaoSenha = {senhaAtual: null, login: null};
    private readonly headers = {
        headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        })
    };
    urlBaseApiAutenticacao: any;
    urlAutenticacaoSistema: any;
    cliente_id?: string;
    cliente_secret?: string;

    constructor(
        public router: Router,
        public http: HttpClient,
        public servicos: ServicosService
    ) {
       this.createSessao();
    }

    private createSessao() {
        const usuarioString = localStorage.getItem('usuario');
        this.usuario = usuarioString ? JSON.parse(usuarioString) : null;
        const menusString = localStorage.getItem('menus');
        this.menus = menusString ? JSON.parse(menusString) : null;
        this.access_token = localStorage.getItem('access_token') as string | undefined;
    }

    buscarURLautenticacao(){
        this.urlBaseApiAutenticacao = this.servicos.urls.urlAutenticacaoSistemaV2;
        this.cliente_id = this.servicos.modulos.cliente_id;
        this.cliente_secret = this.servicos.modulos.cliente_secret;
     }
   
     login(login: string, senha: string, lembrar: boolean = false) {
         this.buscarURLautenticacao();
         const informacao = this.encode(this.cliente_id ?? '', login, this.cliente_secret ?? '', "password", senha);
         const urlAutenticacaoSistema = `${this.urlBaseApiAutenticacao}`;
         console.log(urlAutenticacaoSistema);
         return this.http.post(urlAutenticacaoSistema, informacao).pipe(
             tap(res => {
                 this.configurarSessao(res, true);
             })
         );
     }

     private encode(cliente_id: string, login: string, cliente_secret: string, grantType: string, senha: string) {
        let dados:string = btoa(cliente_id) + ":" + btoa(login) + ":" + btoa(cliente_secret) + ":" + btoa(grantType) + ":" + btoa(senha);
        return { informacoes: btoa(dados) };
    }
   
     refreshToken() {
         const refreshToken = localStorage.getItem('refresh_token');
         const urlRefreshTokenSistema = this.urlAutenticacaoSistema.replace(
                 'grant_type=password', 'grant_type=refresh_token'
             ).replace('username={login}&password={senha}', 'refresh_token=' + refreshToken);
         return this.http.post<any>(urlRefreshTokenSistema, null, this.headers).pipe(
             tap(res => {
                 this.access_token = res.access_token;
                 this.saveToken(res);
             })
         );
     }

    alterarSenha(request: any, login: string) {
        const urlAlteracaoSenha = this.servicos.urls.urlAlteracaoSenha.replace(
            '{login}',
            login
        );
        return this.http.put(urlAlteracaoSenha, request);
    }

    alterarImagem(foto: any) {
        const urlAlteracaoFoto =
            this.servicos.urls.urlPesquisaUsuarios + this.usuario.id + '/foto';
        return this.http.put(urlAlteracaoFoto, foto);
    }

    buscarImagemUsuario() {
        const urlAlteracaoFoto =
            this.servicos.urls.urlPesquisaUsuarios + this.usuario.id + '/foto';
        return this.http.get(urlAlteracaoFoto);
    }

    recuperarSenha(login: string) {
        const urlRecuperacaoSenha = this.servicos.urls.urlRecuperacaoSenha.replace(
            '{login}',
            login
        );
        return this.http.post(urlRecuperacaoSenha, null, this.headers);
    }

    configurarSessao(sessao: any, lembrar: boolean = false) {
        this.usuario = sessao.usuario;
        this.menus = sessao.menus;
        this.access_token = sessao.access_token;
        if (lembrar) {
            this.saveSession(sessao);
        }
    }

    saveSession(sessao: any) {
        this.saveToken(sessao);
        localStorage.setItem('usuario', JSON.stringify(sessao.usuario));
        localStorage.setItem('menus', JSON.stringify(sessao.menus));
    }

    private saveToken(sessao: any) {
        localStorage.setItem('access_token', sessao.access_token);
        localStorage.setItem('token_type', sessao.token_type);
        localStorage.setItem('expires_in', sessao.expires_in);
        localStorage.setItem('refresh_token', sessao.refresh_token);        
    }

    logout() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('token_type');
        localStorage.removeItem('expires_in');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('usuario');
        localStorage.removeItem('menus');
        this.router.navigate(['/login']);
    }
}