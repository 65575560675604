<div class="ui-g-12" *ngIf="item">
  <div class="ui-g-12 ui-lg-2">
    <span class="name">Código</span>
    <span>{{item.codigo}}</span>
  </div>
  <div class="ui-g-12 ui-lg-3">
    <span class="name">Responsável Cadastro</span>
    <span>{{item.responsavelCadastro?.nome}}</span>
  </div>
  <div class="ui-g-12 ui-lg-2">
    <span class="name">Data Cadastro</span>
    <span>{{item.dataCadastro}}</span>
  </div>
  <div class="ui-g-12 ui-lg-3">
    <span class="name">Responsável Atualização </span>
    <span>{{item.responsavelAtualizacao?.nome}}</span>
  </div>
  <div class="ui-g-12 ui-lg-2">
    <span class="name">Data Atualizacao</span>
    <span>{{item.dataAtualizacao}}</span>
  </div>
</div>
