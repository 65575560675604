import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import {ServicosService} from '../service/core/servicos.service';

@Injectable()
export abstract class HttpService {
  protected baseUrl: any;
  constructor(
    protected http: HttpClient,
    protected servicos: ServicosService
  ) {}

  listarDropdownCodigo() {
    return this.listar().pipe(map(this.criarDropdownCodigo));
  }

  listarDropdown() {
    return this.listar().pipe(map(this.criarDropdown));
  }

  listar() {
    return of([]);
  }

  protected criarDropdown(itens: any[]) {
    return itens.map(item => {
      return {
        label: item.nome || item.descricao,
        value: item
      };
    });
  }

  protected criarDropdownCodigo(itens: any[]) {
    return itens.map(item => {
      return {
        label: item.nome || item.descricao,
        value: item.codigo || item.codigoPessoa || item.codigoResponsavel
      };
    });
  }

  public formatNumber(value: any, formato: any) {
    if (this.valorNumericoNulo(value)) {
      switch (formato) {
        case  'number':
          if (!Number.isNaN(value)) {
            value = new Number(value.toString().replace(',', '.'));
          }
          value = value.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2});
          break;
        case 'integer':
          value = value.toLocaleString('pt-BR');
          break;
        case 'percent':
          value = value.toLocaleString('pt-BR', {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2});
          break;
      }
    }
    return value;
  }
  public valorNumericoNulo(value: any) {
    return (value !== undefined && value !== null) || value === 0;
  }
}
