import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogModule} from 'primeng/dialog';
import {KeyFilterModule} from 'primeng/keyfilter';
import { MenubarModule } from 'primeng/menubar';

import {FileUploadModule} from 'primeng/fileupload';
import {InputTextModule} from 'primeng/inputtext';
import {TopbarComponent} from './topbar/topbar.component';
import {ProfileComponent} from './profile/profile.component';
import {FooterComponent} from './footer/footer.component';
import {MenuComponent} from './menu/menu.component';
import {SubmenuComponent} from './menu/submenu/submenu.component';
import { RouterModule } from '@angular/router';
import { ToastModule } from 'primeng/toast';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DialogModule,
    FileUploadModule,
    InputTextModule,
    KeyFilterModule,
    MenubarModule,
    ToastModule


  ],
  declarations: [
    TopbarComponent,
    ProfileComponent,
    FooterComponent,
    MenuComponent,
    SubmenuComponent
  ],
  exports: [
    TopbarComponent,
    ProfileComponent,
    FooterComponent,
    MenuComponent,
    SubmenuComponent
  ]
})
export class LayoutModule { }
