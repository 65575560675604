
import { MenuItem, Message } from 'primeng/api';
import {AlertaService} from '../service/core/alerta.service';

export class PageBase {
  error: Message[] = [];
  breadcrumb: MenuItem[] = [];
  menu: MenuItem[] = [];

  constructor(protected alerta: AlertaService) {
  }

  handleErrorAlert(error: any) {
    if (error.error.detalhe) {
      this.alerta.showError('Erro Interno', error.error.detalhe);
    } else if (error.error.erros) {
      this.alerta.showError('Erro Interno', (error.error.erros.map((err: { mensagem: any; }) => {
        return `${err.mensagem}`;
      }) + '&nbsp;'));
    } else {
      this.alerta.showGeneric(this.genericAlertSeverity(error.status), this.genericErrorMessage(error.status));
    }
  }

  private genericErrorMessage(status: any) {
    let message;
    switch (status) {
      case 400:
        message = 'Requisição inválida.';
        break;
      case 401:
        message = 'Não autorizado.';
        break;
      case 403:
        message = 'Proibido.';
        break;
      case 404:
        message = 'Dados não encontrados.';
        break;
      case 408:
        message = 'Tempo de requisição esgotou.';
        break;
      case 429:
        message = 'Excesso de requisição.';
        break;
      case 500:
        message = 'Erro interno do servidor.';
        break;
      case 502:
        message = 'Bad Gateway.';
        break;
      case 503:
        message = 'Serviço indisponível.';
        break;
      case 503:
        message = 'Serviço indisponível.';
        break;
      default:
        message = 'Serviço indisponível.';
        break;
    }
    return message;
  }

  private genericAlertSeverity(status: any) {
    let severity = '';
    const severityTypes = {aviso: 'warn', erro: 'error', sucesso: 'success'};
    switch (status) {
      case 400:
      case 401:
      case 403:
      case 404:
      case 406:
      case 408:
      case 429:
        severity = severityTypes.erro;
        break;
      case 500:
      case 502:
      case 503:
      case 503:
        severity = severityTypes.erro;
        break;
      default:
        severity = severityTypes.erro;
        break;
    }
    return severity;
  }

  handleError(error: any) {
    if (error.error && error.error.detalhe) {
      this.error = [
        {
          severity: 'error',
          summary: error.error.detalhe
        }
      ];
    } else {
      this.error = [
        {
          severity: 'error',
          summary: 'Serviço indisponível.'
        }
      ];
    }
  }

  handleErrorsAlert(err: any) {
    if (err.error) {
      this.alerta.showErrors(err.error.titulo, err.error.erros);
    }
  }

  protected criarDropdown(itens: any[]) {
    return itens.map(item => {
      return {
        label: item.nome || item.descricao || item.financiador,
        value: item
      };
    });
  }

  protected criarDropdownCodigo(itens: any[]) {
    return itens.map(item => {
      return {
        label: item.nome || item.descricao || item.nomeUsuario,
        value: item.codigo || item.codigoPessoa || item.codigoResponsavel,
      };
    });
  }

  public formatarEmReal(valor: number, formato: string) {
      return `R$ ${this.formatNumber(valor, formato)}`;
  }

  public formatarEmPorcentagem(valor: number, formato: string) {
    return `${this.formatNumber(valor, formato)}%`;
  }

  /**
   * Método responsável por deixar o output no formato pt-Br
   * @param value campo para formatar
   * @param formato number, integer ou percent
   */
  public formatNumber(value: any, formato: any) {
    if (this.valorNumericoNulo(value)) {
      switch (formato) {
        case  'number':
          if (!Number.isNaN(value)) {
            value = new Number(value.toString().replace(',', '.'));
          }
          value = value.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2});
          break;
        case 'integer':
          value = value.toLocaleString('pt-BR');
          break;
        case 'percent':
          value = value.toLocaleString('pt-BR', {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2});
          break;
      }
    }
    return value;
  }

  public valorNumericoNulo(value: number) {
    return (value !== undefined && value !== null) || value === 0;
  }

  public listIsNotEmpty(lista: any[]) {
    return (lista && lista.length > 0);
  }

  formatarTelefone(ddd: any, numero: any) {
    let telefoneFormatado;
    if(ddd && numero) {
    if (numero.length === 8) {
      telefoneFormatado = '(' + ddd + ') ' + numero.substring(0, 4) + '-' + numero.substring(4, 8);
  } else if (numero.length === 9) {
      telefoneFormatado = '(' + ddd + ') ' + numero.substring(0, 5) + '-' + numero.substring(5, 9);
  }
}
  return telefoneFormatado;
  }

}
