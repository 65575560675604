import { Routes } from '@angular/router';
import {AdminComponent} from './admin.component';


export const AdminRoutes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./painel/painel.module').then(m => m.PainelModule)
      },
      {
        path: '',
        loadChildren: () => import('./administracao/administracao.module').then(m => m.AdministracaoModule)
      },
    ]
  }
];
