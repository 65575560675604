import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import {AdminComponent} from '../../../admin/admin.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  
})
export class MenuComponent implements OnInit {
  @Input() reset?: any;
  @Input()
  set menus(value: any[]) {
    this.itens = this.getMenus(value);
  }

  itens?: MenuItem[];
  model?: any[];
  theme = 'blue';
  layout = 'blue';
  version = 'v3';

  constructor(public app: AdminComponent) {}

  ngOnInit() {}

  getMenus(menus:any): MenuItem[] {
    return menus.map((m: any) => {
      return {
        label: m.menu,
        icon: m.icone ? 'fa fa-fw ' + m.icone : null,
        visible: true ,
        routerLink: m.urlPagina ? ['/' + m.urlPagina] : null,
        items: m.subMenus ? this.getMenus(m.subMenus) : null
      };
    });
  }

  changeTheme(theme: string) {
    const themeLink: HTMLLinkElement = <HTMLLinkElement>(
      document.getElementById('theme-css')
    );

    if (this.version === 'v3') {
      themeLink.href = 'assets/theme/theme-' + theme + '.css';
    } else {
      themeLink.href = 'assets/theme/theme-' + theme + '-v4' + '.css';
    }

    this.theme = theme;
  }

  changeLayout(layout: string, special?: boolean) {
    const layoutLink: HTMLLinkElement = <HTMLLinkElement>(
      document.getElementById('layout-css')
    );

    if (this.version === 'v3') {
      layoutLink.href = 'assets/layout/css/layout-' + layout + '.css';
    } else {
      layoutLink.href = 'assets/layout/css/layout-' + layout + '-v4' + '.css';
    }

    this.layout = layout;

    if (special) {
      this.app.darkMenu = true;
    }
  }

  changeVersion(version: string) {
    const themeLink: HTMLLinkElement = <HTMLLinkElement>(
      document.getElementById('theme-css')
    );
    const layoutLink: HTMLLinkElement = <HTMLLinkElement>(
      document.getElementById('layout-css')
    );

    if (version === 'v3') {
      this.version = 'v3';
      themeLink.href = 'assets/theme/theme-' + this.theme + '.css';
      layoutLink.href = 'assets/layout/css/layout-' + this.layout + '.css';
    } else {
      themeLink.href = 'assets/theme/theme-' + this.theme + '-v4' + '.css';
      layoutLink.href =
        'assets/layout/css/layout-' + this.layout + '-v4' + '.css';
      this.version = '-v4';
    }
  }
}
