import {Component, OnInit, ViewChild} from '@angular/core';
import { PageBase } from '../page-base';
import { AuthService } from 'src/app/service/core/auth.service';
import { HintService } from 'src/app/service/crud/hint.service';
import { AlertaService } from 'src/app/service/core/alerta.service';


@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss']
})
export class HintComponent extends PageBase implements OnInit {
  @ViewChild('op', {static: true})
  op:any;

  constructor(
    public auth: AuthService,
    private hintService: HintService,
    protected override alerta: AlertaService
  ) {
    super(alerta);
  }

  texto?: string;
  nome?: string;
  id?: number;

  ngOnInit() {
  }

  mostrar(event: any, id: any) {
    this.texto = '';
    if (this.op.visible) {
      this.op.hide();
    }
    setTimeout(() => {
      this.op.toggle(event);
      this.op.visible = true;
      this.buscar(id, this.texto);
    }, 210);
  }

  mostrarNovo(event: any, id: any, nome: any) {
    if (this.op.visible) {
      this.op.hide();
    }
    setTimeout(() => {
      this.op.toggle(event);
      this.op.visible = true;
      this.buscar(id, nome);
    }, 210);
  }

  // mostrar(event, id) {
  //   this.texto = '';
  //   this.op.toggle(event);
  //   this.buscar(id);
  // }

  private buscar(id: any, nome: any) {
    this.id = id;
    this.hintService.buscar(id).subscribe(
      res => {
        this.texto = res.descricao;
        this.nome = res.nome;
      },
      err => {
        if (this.naoEncontrouAjuda(err)) {
          this.salvarCodigoAjuda(id, nome);
        }
        this.handleErrorAlert(err);
      }
    );
  }

  private naoEncontrouAjuda(err:any) {
    if (err.status = 404) {
      if (err.error && err.error.detalhe && err.error.detalhe === 'Nenhuma ajuda foi encontrado.') {
        return true;
      }
    }
    return false;
  }

  private salvarCodigoAjuda(id:any, nome: string) {
    // tslint:disable-next-line: quotemark
    console.log("Os perfis que acessam o menu “Usuário” são “Master DN” e “Técnico PMO DR”".replace('“', '\"').replace('”', '\"'));
    const ajuda = {codigo: id, nome: nome.replace(/“/g, '\"').replace(/”/g, '\"')};
    this.hintService.criar(ajuda).subscribe(
      res => {
        this.texto = res.descricao;
        this.nome = res.nome;
        this.alerta.showInfo('Não há uma descrição de ajuda para esse campo. Essa é a hora!');
      },
      err => {
        this.handleErrorAlert(err);
      }
    );
  }

  salvar() {
    this.hintService.atualizar(this.id ?? 0, {descricao: this.texto?.replace(/“/g, '\"').replace(/”/g, '\"'), nome: this.nome || ''}).subscribe(
      res => {
        this.alerta.showSuccess('Dica atualizada.');
      },
      err => {
        this.handleErrorAlert(err);
      }
    );
    this.op.hide();
  }
}
