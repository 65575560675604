import {Injectable} from '@angular/core';
import {Message} from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class AlertaService {
    public msgs: Message[] = [];

    constructor() {
    }

    private space = '&nbsp;';


    mensagemTeste():void {

    }

    showSuccess(summary: string, detail: string = this.space) {
        const severity = 'success';
        this.showMessage(severity, summary, detail);
    }

    showInfo(summary: string, detail: string = this.space) {
        const severity = 'info';
        this.showMessage(severity, summary, detail);
    }

    showWarn(summary: string, detail: string = this.space) {
        const severity = 'warn';
        this.showMessage(severity, summary, detail);
    }

    showError(summary: string, detail: string = this.space) {
        const severity = 'error';
        this.showMessage(severity, summary, detail);
    }

    showErrors(tituloErro: any, erros: any[]) {
        const severity = 'error';
        this.msgs = [];
        if (erros.length > 0) {
            erros.forEach(erro => {
                this.showMultipleMessage(severity, tituloErro, erro.mensagem);
            });
        } else {
            this.showMessage(severity, 'Erro', tituloErro);
        }
    }

    showWarns(tituloErro: any, warns: any[]) {
        this.msgs = [];
        if (warns.length > 0) {
            warns.forEach(warn => {
                this.showMultipleMessage('warn', tituloErro, warn.mensagem);
            });
        } else {
            this.showMessage('warn', 'Alerta', 'Alguma informação está incorreta.');
        }
    }

    private showMessage(severity: string, summary: string, detail: string) {
        this.msgs = [];
        this.msgs.push({severity: severity, summary: summary, detail: detail});
    }

    private showMultipleMessage(severity: string, summary: string, detail: string) {
        this.msgs.push({severity: severity, summary: summary, detail: detail});
    }

    showGeneric(severity: string, summary: string, detail: string = this.space) {
        this.showMessage(severity, summary, detail);
    }
}
