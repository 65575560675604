import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {HttpCrudService} from '../../shared/http-crud.service';
import {ServicosService} from '../core/servicos.service';
import { AdminModule } from 'src/app/admin/admin.module';


@Injectable({
  providedIn: AdminModule
})
export class HintService extends HttpCrudService {
  constructor(public override http: HttpClient, public override servicos: ServicosService) {
    super(http, servicos);
    this.baseUrl = servicos.modulos.administracao + '/api/ajudas/';
  }
}
