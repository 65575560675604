    <p-dialog header="Alterar Imagem do Perfil" [(visible)]="showCrop" [style]="{width: '30vw'}">
    <div id="crop" class="ui-g-12 crop">
        <img id="imagemCrop" src="" alt="Imagem a ser cortada" >
    </div>
    <p>
        Para trocar a foto do seu perfil click no botão Selecionar Imagem. 
    </p>
    <div class="botoes">
        <p-fileUpload #fileUpload mode="basic" [auto]="true" [customUpload]="true" [showCancelButton]="true"
                      (uploadHandler)="select($event, fileUpload)" chooseLabel="Selecionar Imagem" accept="image/*"
                      [maxFileSize]="1000000" class="pull-left"></p-fileUpload>
    </div>

    <div class="botoes">
        <p-button type="button" class="success-btn" label="Salvar Imagem" (click)="salvarImagem()"></p-button>
    </div>

   </p-dialog>

<div class="topbar clearfix">
    <div class="topbar-left">
        <a href="#"
           [ngClass]="{'menu-button-rotate': app.rotateMenuButton}">
            <h3 class="tobbar-title">{{nomeSistema}}</h3>
        </a>
    </div>

    <div class="topbar-right">
        <a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)"
           [ngClass]="{'menu-button-rotate': app.rotateMenuButton}">
            <i class="fa fa-angle-left"></i>
        </a>

        <a id="topbar-menu-button" href="#" (click)="app.onTopbarMenuButtonClick($event)">
            <i class="fa fa-bars"></i>
        </a>

        <ul class="topbar-items fadeInDown" [ngClass]="{'topbar-items-visible': app.topbarMenuActive}">
            <li #profile class="profile-item" *ngIf="app.profileMode==='top'||app.isHorizontal()"
                [ngClass]="{'active-top-menu':app.activeTopbarItem === profile}">

                <a href="#" (click)="app.onTopbarItemClick($event,profile)">
                    <img class="profile-image" id="fotoUsuario" src="" />
                    <span class="topbar-item-name">{{auth.usuario.nome}}</span>
                </a>

                <ul class="layout-menu fadeInDown">
                    <li role="menuitem">
                        <a>
                            <span><b>Nome: </b></span>
                            <span>{{auth.usuario.nome}}</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a>
                            <span><b>Login: </b></span>
                            <span>{{auth.usuario.login}}</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a>
                            <span><b>Perfil: </b></span>
                            <span>{{auth.usuario.perfil}}</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a>
                            <span><b>Email: </b></span>
                            <span>{{auth.usuario.email}}</span>
                        </a>
                    </li>
                    <li role="menuitem" (click)="showModalCrop()">
                        <a href="javascript:">
                            <i class="fa-solid fa-file-image"></i>
                            <span>Alterar Foto do Perfil</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="javascript:" routerLink="/alterar-senha">
                            <i class="fa fa-fw fa fa-key"></i>
                            <span>Alterar Senha</span>
                        </a>
                    </li>
                    <li role="menuitem" (click)="logout()">
                        <a href="javascript:">
                            <i class="fa fa-fw fa fa-sign-out"></i>
                            <span>Logout</span>
                        </a>
                    </li>
                </ul>
            </li>

            <!-- <li #search class="search-item" [ngClass]="{'active-top-menu':app.activeTopbarItem === search}"
                (click)="app.onTopbarItemClick($event,search)">
                <div class="topbar-search c-search-conteiner">
                    <div class="p-inputgroup">
                        <input type="text" pInputText pKeyFilter="int" [(ngModel)]="codigoDoProjeto"
                               (keypress)="buscarProjeto($event)" placeholder="Código do Projeto">
                        <button type="button" pButton pRipple icon="pi pi-search" styleClass="p-button-warn"
                                (click)="buscarProjeto($event)"></button>
                    </div>
                </div>
            </li> -->
        </ul>
    </div>
</div>
