import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { combineLatest } from 'rxjs/operators';
import {ServicosService} from './servicos.service';


@Injectable({
  providedIn: 'root'
})
export class ServicosGuard implements CanActivate {
  constructor(private servicos: ServicosService, private router: Router) {}

  canActivate(): Promise<boolean> {
    return new Promise(resolve => {
      this.servicos
        .buscarUrls()
        .pipe(combineLatest(this.servicos.buscarModulos()))
        .subscribe(
          ([urls, modulos]: [any, any]) => { 
            resolve(true);
          },
          (err:any) => {
            resolve(false);
          }
        );
    });
  }
}
