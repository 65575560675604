import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public auth: AuthService) {}
  usuario: any;
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.usuario = this.auth.usuario;
    if (this.auth.usuario) {
      return true;
    }
    this.auth.logout();
    return false;
  }
}
