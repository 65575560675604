import {Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';
import {AuthService} from '../../../service/core/auth.service';


@Component({
  selector: 'app-inline-profile',
  animations: [
    trigger('menu', [
      state('hidden', style({
        height: '0px'
      })),
      state('visible', style({
        height: '*'
      })),
      transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  active?: boolean;

  constructor(private router: Router,
              public auth: AuthService) {
  }

  ngOnInit() {
  }

  onClick(event:any) {
    this.active = !this.active;
    event.preventDefault();
  }

  logout() {
    this.router.navigate(['/login']);
    this.auth.logout();
  }
}
