import { ServicosGuard } from './service/core/servicos-guard.service';
import { AuthGuard } from './service/core/auth-guard.service';
import { Routes } from '@angular/router';

export const AppRoutes: Routes =
    [{
        path: '',
        redirectTo: 'dashboard-simples',
        pathMatch: 'full',

    }, {
        path: '',
        children: [{
            canActivate: [ServicosGuard],
            path: '',
            loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
        }]
    }, {
        path: '',
        canActivate: [AuthGuard, ServicosGuard],
        children: [{
            path: '',
            loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
        }],
    }];
    //{ path: '**', redirectTo: '/notfound' }

