import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import {ServicosService} from '../service/core/servicos.service';
import {HttpService} from './http.service';
import { environment } from 'src/environments/environment';


@Injectable()
export abstract class HttpCrudService extends HttpService {
  protected override baseUrl:any = environment.baseUrl;

  constructor(protected override http: HttpClient, protected override servicos: ServicosService) {
    super(http, servicos);
  }

  buscar(codigo: number) {
    const url = this.baseUrl + codigo;
    return this.http.get<any>(url);
  }

  listar2() {
    const url = this.baseUrl;
    return this.http.get<any>(url).pipe(tap(this.converterDadosUsuarios));
  }

  copiar(codigo: any) {
    const url = this.baseUrl + codigo + '/copias';
    return this.http.post<any>(url, null);
  }

  desativar(codigo: any) {
    const url = this.baseUrl + codigo + '/desativados';
    return this.http.put<any>(url, null);
  }

  criar(item: any) {
    const url = this.servicos.urls.urlNovoUsuarioSistema;
    return this.http.post<any>(url, item);
  }

  atualizar(codigo: number, item: any) {
    const url = this.servicos.urls.urlAtualizaUsuarioSistema.replace('{codigoUsuario}', codigo);
    return this.http.put<any>(url, item);
  }

  salvar(itens: any) {
    const url = this.baseUrl;
    return this.http.put<any>(url, itens);
  }

  remover(codigo: number) {
    const url = this.servicos.urls.urlAtualizaUsuarioSistema.replace('{codigoUsuario}', codigo);
    return this.http.delete<any>(url);
  }

  mudarStatus(codigo: number) {
    const url = this.baseUrl + codigo;
    return this.http.patch<any>(url, '');
  }

  converterDadosUsuarios(itens: any[]) {
    itens.forEach(item => {
      if (!item.nomeUsuarioCadastro && item.responsavelCadastro) {
        item.nomeUsuarioCadastro = item.responsavelCadastro.nome;
      }
      if (!item.nomeUsuarioAtualizacao && item.responsavelAtualizacao) {
        item.nomeUsuarioAtualizacao = item.responsavelAtualizacao.nome;
      }
    });
  }
}
