import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import {AuthService} from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  authService?: AuthService;

  constructor(private injector: Injector) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.authService = this.injector.get(AuthService);
    const access_token = this.authService.access_token;

    return next.handle(this.addToken(request, access_token || '')).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 401:
              // return this.handleTokenExpired(request, next);
              this.logoutUser(error);
              return throwError(error);
            case 400:
              return this.handleRefreshTokenExpired(error);
            default:
              return throwError(error);
          }
        } else {
          return throwError(error);
        }
      })
    );
  }

  // private getContentType(request: HttpRequest<any>) {
  //   const contentTypeConfigured = request.headers.has('Content-Type');
  //   if (contentTypeConfigured) {
  //     if (request.headers.get('Content-Type') === 'multipart/form-data') {
  //       request.headers.delete('Content-Type');
  //       // return `${request.headers.get('Content-Type')}; boundary=HereGoes`;
  //     } else {
  //       return 'application/x-www-form-urlencoded';
  //     }
  //   } else {
  //     return 'application/json';
  //   }
  // }

  private getContentType(request: HttpRequest<any>) {
    const contentTypeConfigured = request.headers.has('Content-Type');
    if (contentTypeConfigured) {
      if (request.headers.get('Content-Type') === 'multipart/form-data') {
        request.headers.delete('Content-Type');
        // Aqui você pode retornar algo, como 'multipart/form-data' para indicar que o cabeçalho foi removido
        return 'multipart/form-data'; 
      } else {
        return 'application/x-www-form-urlencoded';
      }
    } else {
      return 'application/json';
    }
  }

  montarClone(request: HttpRequest<any>, access_token: string, contentType:any) {
    if (contentType) {
      return request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + access_token,
          'Content-Type': contentType
        }
      });
    } else {
      return request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + access_token
        }
      });
    }
  }

  private addToken(
    request: HttpRequest<any>,
    access_token: string
  ): HttpRequest<any> {
    return  this.montarClone(request, access_token, this.getContentType(request));
  }

  handleTokenExpired(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next('');
      return this.authService?.refreshToken().pipe(
        switchMap((res: any) => {
          const newToken = res.access_token;
          if (newToken) {
            this.tokenSubject.next(newToken);
            return next.handle(this.addToken(request, newToken));
          }
          // If we don't get a new token, we are in trouble so logout.
          return this.logoutUser('');
        }),
        catchError(error => this.logoutUser(error)),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => next.handle(this.addToken(request, token)))
      );
    }
  }

  handleRefreshTokenExpired(error:any) {
    if (
      error &&
      error.status === 400 &&
      error.error &&
      error.error.error === 'invalid_grant'
    ) {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      return this.logoutUser(error);
    }
    return throwError(error);
  }

  logoutUser(error:any) {
    this.authService?.logout();
    return throwError(error);
  }
}
