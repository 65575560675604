import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DadosCadastroModule} from './dados-cadastro/dados-cadastro.module';
import {CheckboxModule} from 'primeng/checkbox';
import {RadioButtonModule} from 'primeng/radiobutton';
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {MessagesModule} from 'primeng/messages';
import {TableModule} from 'primeng/table';
import {PanelModule} from 'primeng/panel';
import {SpinnerModule} from 'primeng/spinner';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {TooltipModule} from 'primeng/tooltip';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {HintModule} from './hint/hint.module';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {TreeModule} from 'primeng/tree';
import {ContextMenuModule} from 'primeng/contextmenu';
import {FileUploadModule} from 'primeng/fileupload';
import {ListboxModule} from 'primeng/listbox';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ErrorComponent} from './error/error.component';
import {SituacaoPipe} from './pipes/situacao.pipe';
import {NgModule} from '@angular/core';
import { AlertaComponent } from './alerta/alerta.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DadosCadastroModule,
    CheckboxModule,
    RadioButtonModule,
    CommonModule,
    ButtonModule,
    CardModule,
    DropdownModule,
    InputTextModule,
    MessagesModule,
    TableModule,
    PanelModule,
    SpinnerModule,
    BreadcrumbModule,
    TooltipModule,
    OverlayPanelModule,
    HintModule,
    ConfirmDialogModule,
    DialogModule,
    TreeModule,
    ContextMenuModule,
    FileUploadModule,
    ListboxModule,
    ToggleButtonModule,
  ],
  declarations: [ErrorComponent, SituacaoPipe, AlertaComponent],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    DadosCadastroModule,
    CheckboxModule,
    RadioButtonModule,
    DialogModule,
    CommonModule,
    ButtonModule,
    CardModule,
    DropdownModule,
    InputTextModule,
    MessagesModule,
    TableModule,
    PanelModule,
    CheckboxModule,
    SpinnerModule,
    BreadcrumbModule,
    TooltipModule,
    ErrorComponent,
    SituacaoPipe,
    OverlayPanelModule,
    HintModule,
  ],
  providers: []
})
export class SharedModuleSystms {}
